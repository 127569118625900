import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

interface BannerDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerDefault = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: 400px;
`

const StyledPlaatjie = styled(Plaatjie)<{ image: any }>`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};
`

const BannerDefault: React.FC<BannerDefaultProps> = ({ fields }) => (
  <StyledBannerDefault>
    <BannerWrapper>
      <StyledPlaatjie alt="banner" image={fields?.image} />
      <div className="container py-5">
        <Content content={fields.description || ''} />
      </div>
    </BannerWrapper>
  </StyledBannerDefault>
)

export default BannerDefault
